<template>
      <div class="card mb-5 mb-xl-10">
        <div
          class="card-header border-0 cursor-pointer bg-success"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#submitted"
          aria-expanded="true"
          aria-controls="form"
        >
          <div class="card-title m-0">
            <h3 class="fw-bolder text-light m-0">
              Claim 1: Approved Bill
            </h3>
          </div>
        </div>
        <div id="submitted" class="collapse show">
          <div class="collapse show">
            <div class="card-body border-top p-6">
              <div class="current" data-kt-stepper-element="content">
                <div class="card mb-5 mb-xl-10">
                  <div
                    class="card-header border-0 cursor-pointer bg-secondary"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#tranch1"
                    aria-expanded="true"
                    aria-controls="form"
                  >
                    <div class="card-title m-0">
                      <h3 class="fw-bolder text-dark m-0">
                        Tranche-1
                      </h3>
                    </div>
                  </div>
                  <div id="tranch1" class="collapse show">
                    <div class="collapse show">
                      <div class="card-body border-top p-4">
                        <div class="current" data-kt-stepper-element="content">
                            <ul class="list-group list-group-horizontal-lg">
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\logo.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>37</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>SEIP</h4>
                                </router-link>
                              </div>
                            </li>
                          <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bacco.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>25</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BACCO</h4>
                                </router-link>
                              </div>
                            </li>
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\basis.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>16</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BASIS</h4>
                                </router-link>
                              </div>
                            </li>
                             <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bigm.jpg" width="50" alt="metronic" class="mr-3" />
                                    <strong>28</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BIGM</h4>
                                </router-link>
                              </div>
                            </li>
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bkmea.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>15</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BKMEA</h4>
                                </router-link>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-5 mb-xl-10">
                  <div
                    class="card-header border-0 cursor-pointer bg-primary"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#tranche_additional1"
                    aria-expanded="true"
                    aria-controls="form"
                  >
                    <div class="card-title m-0">
                      <h3 class="fw-bolder text-light m-0">
                        Tranche-Additional-1
                      </h3>
                    </div>
                  </div>
                  <div id="tranche_additional1" class="collapse show">
                    <div class="collapse show">
                      <div class="card-body border-top p-4">
                        <div class="current" data-kt-stepper-element="content">
                          <ul class="list-group list-group-horizontal-lg">
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\logo.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>37</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>SEIP</h4>
                                </router-link>
                              </div>
                            </li>
                          <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bacco.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>25</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BACCO</h4>
                                </router-link>
                              </div>
                            </li>
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\basis.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>16</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BASIS</h4>
                                </router-link>
                              </div>
                            </li>
                             <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bigm.jpg" width="50" alt="metronic" class="mr-3" />
                                    <strong>28</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BIGM</h4>
                                </router-link>
                              </div>
                            </li>
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bkmea.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>15</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BKMEA</h4>
                                </router-link>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-5 mb-xl-10">
                  <div
                    class="card-header border-0 cursor-pointer bg-info"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#tranche2"
                    aria-expanded="true"
                    aria-controls="form"
                  >
                    <div class="card-title m-0">
                      <h3 class="fw-bolder text-light m-0">
                        Tranche-2
                      </h3>
                    </div>
                  </div>
                  <div id="tranche2" class="collapse show">
                    <div class="collapse show">
                      <div class="card-body border-top p-4">
                        <div class="current" data-kt-stepper-element="content">
                            <ul class="list-group list-group-horizontal-lg">
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\logo.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>37</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>SEIP</h4>
                                </router-link>
                              </div>
                            </li>
                          <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bacco.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>25</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BACCO</h4>
                                </router-link>
                              </div>
                            </li>
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\basis.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>16</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BASIS</h4>
                                </router-link>
                              </div>
                            </li>
                             <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bigm.jpg" width="50" alt="metronic" class="mr-3" />
                                    <strong>28</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BIGM</h4>
                                </router-link>
                              </div>
                            </li>
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bkmea.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>15</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BKMEA</h4>
                                </router-link>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-5 mb-xl-10">
                  <div
                    class="card-header border-0 cursor-pointer bg-warning"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#tranche_additional_2"
                    aria-expanded="true"
                    aria-controls="form"
                  >
                    <div class="card-title m-0">
                      <h3 class="fw-bolder text-light m-0">
                        Tranche-Additional-2
                      </h3>
                    </div>
                  </div>
                  <div id="tranche_additional_2" class="collapse show">
                    <div class="collapse show">
                      <div class="card-body border-top p-4">
                        <div class="current" data-kt-stepper-element="content">
                              <ul class="list-group list-group-horizontal-lg">
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\logo.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>37</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>SEIP</h4>
                                </router-link>
                              </div>
                            </li>
                          <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bacco.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>25</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BACCO</h4>
                                </router-link>
                              </div>
                            </li>
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\basis.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>16</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BASIS</h4>
                                </router-link>
                              </div>
                            </li>
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bigm.jpg" width="50" alt="metronic" class="mr-3" />
                                    <strong>28</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BIGM</h4>
                                </router-link>
                              </div>
                            </li>
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bkmea.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>15</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BKMEA</h4>
                                </router-link>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-5 mb-xl-10">
                  <div
                    class="card-header border-0 cursor-pointer bg-primary"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#tranche3"
                    aria-expanded="true"
                    aria-controls="form"
                  >
                    <div class="card-title m-0">
                      <h3 class="fw-bolder text-light m-0">
                        Tranche-3
                      </h3>
                    </div>
                  </div>
                  <div id="tranche3" class="collapse show">
                    <div class="collapse show">
                      <div class="card-body border-top p-4">
                        <div class="current" data-kt-stepper-element="content">
                            <ul class="list-group list-group-horizontal-lg">
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\logo.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>37</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>SEIP</h4>
                                </router-link>
                              </div>
                            </li>
                          <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bacco.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>25</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BACCO</h4>
                                </router-link>
                              </div>
                            </li>
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\basis.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>16</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BASIS</h4>
                                </router-link>
                              </div>
                            </li>
                             <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bigm.jpg" width="50" alt="metronic" class="mr-3" />
                                    <strong>28</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BIGM</h4>
                                </router-link>
                              </div>
                            </li>
                            <li class="list-group-item mr-4 mb-4 card rounded">
                              <div class="d-flex">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <img src="media\logos\bkmea.png" width="50" alt="metronic" class="mr-3" />
                                    <strong>15</strong>
                                </router-link>
                              </div>
                              <div class="mt-3 px-1" style="border-top: 2px solid #1db78d !important">
                                <router-link to="/association/entity-list" class="text-warning fw-bold fs-6 mt-2">
                                    <h4>BKMEA</h4>
                                </router-link>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>